import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/ussgw";
} else {
    url = "/lgiot/user/ussgw";
}

/**
 * 登录
 * @param {
 *  platformType  平台类型，C端用户登录无需此值 - 1:IOT后台; 2:IOT平台
 *  tid  终端ID
 *  *password  密码
 *  *type 登录类型 - 1:用户名密码模式; 2:手机号验证码模式
 *  *userName 用户名
 *  vcode 校验码
 *  smsId smsId
 * } data
 * @returns
 */
const login = data => {
    return service({
        url: url + '/login',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 注册
 * @param {
 *  *password 密码
 *  platformType 平台类型，C端用户登录无需此值 - 1:IOT后台; 2:IOT平台
 *  *userName 用户名
 *  channel 注册来源 - 1:IOT后台; 2:IOT平台; 3:APP; 4:微信公众号/小程序; 5:支付宝
 *  *code 验证码
 *  *smsId smsId
 * } data
 * @returns
 */
const register = data => {
    return service({
        url: url + '/register',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取用户登录信息
 * @returns
 */
const getlogininfo = () => {
    return service({
        url: url + '/getlogininfo',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 注销登录
 * @returns
 */
const loginout = () => {
    return service({
        url: url + '/loginout',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 企业切换
 * @param {
 *  *enterpriseId 目标企业ID,传值为-1时表示切换到个人空间
 * } data
 * @returns
 */
const changeEnterprise = data => {
    return service({
        url: url + '/enterprise/change',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 通过用户名查找用户基本信息，只返回用户ID和用户名
 * @param {
 *  *userName 用户名
 * } data
 * @returns
 */
const findUserBaseInfo = data => {
    return service({
        url: url + '/user/baseinfo/find',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取当前登录的管理员用户信息，IOT平台和IOT后台适用
 * @returns
 */
const getAdminuserInfo = () => {
    return service({
        url: url + '/adminuser/info/get',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改用户信息
 * @param {
 *  address 地址
 *  headImg 头像
 *  nickname 别名
 *  realname 实名
 * } data
 * @returns
 */
const modifyUserInfo = data => {
    return service({
        url: url + '/user/info/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 发送手机验证码
 * @param {
 *  *phoneCode 手机号
 *  *type 验证码类型 1.注册 2.登录 3.修改密码
 * } data
 * @returns
 */
const sendSms = data => {
    return service({
        url: url + '/sendSms',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改密码
 * @param {
 *  oldPassword 旧密码
 *  password 密码
 * } data
 * @returns
 */
const modifyPassword = data => {
    return service({
        url: url + '/user/info/modifyPassword',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改手机（第二步）
 * @param {
 *  *code 验证码
 *  *newPhone 新手机号
 *  *oldCode 旧验证码
 *  *oldSmsId 旧smsId
 *  *smsId smsId
 * } data
 * @returns
 */
const modifyPhone = data => {
    return service({
        url: url + '/user/info/modifyPhone',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 忘记密码
 * @param {
 *  *phoneCode 手机号
 *  *code 验证码
 *  *password 密码
 *  *smsId smsId
 * } data
 * @returns
 */
const restPassword = data => {
    return service({
        url: url + '/user/info/restPassword',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取当前用户手机验证码
 * @param {
 *  *type 验证码类型 1.注册 2.登录 3.修改密码 4.修改手机号
 * } data
 * @returns
 */
const sendUserSms = data => {
    return service({
        url: url + '/sendUserSms',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改手机（第一步）
 * @param {
 *  *code 验证码
 *  *smsId smsId
 * } data
 * @returns
 */
const checkUserPhone = data => {
    return service({
        url: url + '/user/info/checkUserPhone',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 判断用户是否已经被注册
 * @param {
 *   *userId 用户ID
 * }
 * @returns {AxiosPromise}
 */
const isAllowRegistration = data => {
    return service({
        url: url + '/isAllowRegistration',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 通过手机号验证码修改密码
 * @param {
 *     *code 验证码
 *     *password 密码
 *     *smsId
 * }
 * @returns {*}
 */
const modifyPasswordByPhone = data => {
    return service({
        url: url + '/user/info/modifyPasswordByPhone',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}


export default {
    login,
    register,
    getlogininfo,
    loginout,
    changeEnterprise,
    findUserBaseInfo,
    getAdminuserInfo,
    modifyUserInfo,
    sendSms,
    modifyPassword,
    modifyPhone,
    restPassword,
    sendUserSms,
    checkUserPhone,
    isAllowRegistration,
    modifyPasswordByPhone
}
