import Vue from 'vue'

import ElementUI from 'element-ui'
import '../theme/index.css'
import '../theme/alicon/iconfont.css'
import '../theme/coicon/iconfont.css'
import '../theme/fonts/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import animated from 'animate.css'

//富文本格式
import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme

//导入token控制模块
import token from "@/utils/auth.js";
//导入弹窗文件
import dialog from "@/utils/dialog.js";
//导入公共配置文件
import config from "@/utils/util.js";
//mock数据
import message from '@/utils/message';
//md5加密
import md5 from '@/utils/md5';
//请求拦截
import request from '@/utils/request';
//接口层
import api from '@/api/baseAPI';


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(animated)

Vue.prototype.$token = token;
//公共弹窗文件
Vue.prototype.$dialog = dialog;
//公共配置文件
Vue.prototype.$config = config;
// 在原型上扩展,这样不用在每个页面都导入request
Vue.prototype.$request = request;
//mock数据
Vue.prototype.$message = message;
//接口层
Vue.prototype.$api = api;
//md5加密
Vue.prototype.$md5 = md5;

let app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app;
