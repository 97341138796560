//弹窗类型
const TOAST_SUCCESS = 1;
const TOAST_WARNING = 2;
const TOAST_INFO = 3;
const TOAST_ERROR = 4;

/**
 * 格式化菜单路由
 * @param {菜单列表} menuList
 * @returns
 */
const formatMenu = menuList => {
    let menu = [];
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].children) {
            menu.push.apply(menu, formatMenu(menuList[i].children));
        }
        if (!menuList[i].uri) continue;
        let url = menuList[i].pagePath;
        menu.push({
            path: menuList[i].uri,
            name: getCaption(menuList[i].uri, "\/"),
            component: (resolve) => require([`@/views/${url.substr(8, url.length - 1)}`], resolve),
            meta: {
                title: menuList[i].name,
                parentPath: menuList[i].uri
            }
        });
    }
    return menu;
}

/**
 * 生成随机字符串
 * @param {number} e
 * @returns
 */
const randomString = (e) => {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}

/**
 * 截取特定字符串之后所以内容
 * @param {目标字符串} obj
 * @param {截取的分割字符串} str
 * @returns
 */
const getCaption = (obj, str) => {
    let index = obj.lastIndexOf(str);
    obj = obj.substring(index + 1, obj.length);
    return obj;
}


/**
 * 时间戳转换
 * @param {时间戳} datetime
 * @param {是否精确到秒} isAccurate
 * @returns
 */
const format = (datetime, isAccurate, isZH) => {
    let date = new Date(datetime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let year = date.getFullYear(),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        sdate = ("0" + date.getDate()).slice(-2),
        hour = ("0" + date.getHours()).slice(-2),
        minute = ("0" + date.getMinutes()).slice(-2),
        second = ("0" + date.getSeconds()).slice(-2);
    // 拼接
    let result = year + "-" + month + "-" + sdate;
    if (isAccurate) result += ' ' + hour + ':' + minute + ':' + second;
    if (isZH) result = `${year}${month}${sdate}${hour}${minute}${second}`
    // 返回
    return result;
}

/**
 * 数组匹配
 * @param {目标数组} list
 * @param {目标id} id
 * @param {id的key} key
 * @param {返回参数的key} backKey
 * @returns
 */
const getMsgItemUtil = (list, id, key, backKey) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i][key] == id) {
            return list[i][backKey];
        }
    }
}

/**
 * 获取文件后缀名
 * @param file
 * @returns {string}
 */
const getType = file => {
    let filename = file;
    let index1 = filename.lastIndexOf(".");
    let index2 = filename.length;
    let type = filename.substring(index1, index2);
    return type;
}

/**
 * 判断日期是否为NaN
 * @param date
 * @returns {boolean}
 */
const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime())
}

/**
 * 深拷贝
 * @param {JSON对象} data
 * @returns
 */
const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
}

/**
 * 验证手机号正则
 * @param {手机号} phone
 * @returns
 */
const phoneRight = (phone) => {
    return /^0?1[0-9]{10}$/.test(phone)
}

/**
 * 修改地址栏参数
 * @param arg key
 * @param arg_val val
 */
const changeURLArg = (arg, arg_val) => {
    let url = location.href;
    let pattern = arg + '=([^&]*)';
    let replaceText = arg + '=' + arg_val;
    let msg = "";
    if (url.match(pattern)) {
        let tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        msg = tmp;
    } else {
        msg = url + (url.match('[\?]') ? '&' : '?') + replaceText;
    }
    if (!!(window.history && history.pushState)) {
        history.replaceState(null, "", msg);
    }
}

/**
 * 组装URL
 * @param url 目标URL
 * @param arg key
 * @param arg_val value
 * @returns {*}
 */
const assembleURLArg = (url, arg, arg_val) => {
    let pattern = arg + '=([^&]*)';
    let replaceText = arg + '=' + arg_val;
    let msg = "";
    if (url.match(pattern)) {
        let tmp = '(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        msg = tmp;
    } else {
        msg = url + (url.match('[\?]') ? '&' : '?') + replaceText;
    }
    return msg
}

/**
 * 给数字千分位加上逗号
 * @param mun
 * @returns {string}
 */
const farmat = (mun) => {
    if (mun === null) return;
    let m = parseInt(mun).toString();
    let len = m.length;
    if (len <= 3) return m;
    let n = len % 3;
    if (n > 0) {
        return m.slice(0, n) + "," + m.slice(n, len).match(/\d{3}/g).join(",")
    } else {
        return m.slice(n, len).match(/\d{3}/g).join(",")
    }
}

/**
 * 下载文件
 * @param res
 * @param name
 */
const downloadFile = (res, name) => {
    let fileName = name;
    let objectUrl = URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.download = decodeURI(fileName)
    link.href = objectUrl
    link.click();
}

/**
 * 阿拉伯数字转中文数字
 * @param num
 * @returns {string}
 */
const noToChinese = num => {
    const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const arr2 = ['','十', '百','千', '万','十','百', '千','亿', '十', '百', '千', '万','十','百', '千','亿' ];
    if (!num || isNaN(num)) return '零';
    const numStr = num.toString().split('');
    let result = '';
    for (let i = 0; i < numStr.length; i++) {
        const des_i = numStr.length - 1 - i;
        result = arr2[i] + result;
        const arr1_index = numStr[des_i];
        result = arr1[arr1_index] + result;
    }
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    result = result.replace(/零+/g, '零');
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    result = result.replace(/亿万/g, '亿');
    result = result.replace(/零+$/, '');
    result = result.replace(/^一十/g, '十');
    return result;
}

/**
 * Uint8Array转字符串
 * @param fileData
 * @returns {string}
 * @constructor
 */
const Uint8ArrayToString = fileData => {
    let dataString = "";
    for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
    }

    return dataString

}

//二维码对应的app下载地址
const url = "http://xxxxxx";

export default {
    TOAST_SUCCESS,
    TOAST_WARNING,
    TOAST_INFO,
    TOAST_ERROR,

    url,

    getMsgItemUtil,

    format,
    getType,
    isValidDate,
    deepCopy,
    getCaption,
    formatMenu,
    randomString,
    phoneRight,
    changeURLArg,
    assembleURLArg,
    noToChinese,
    farmat,
    downloadFile,
    Uint8ArrayToString
};




