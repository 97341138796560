import Vue from 'vue'
import Router from 'vue-router';
import config from './config';
import token from '@/utils/auth';
import app from '../main';

Vue.use(Router);
let router = new Router(config);
// 注册一个全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (to.path !== "/login") {
        if (to.path === "/protocol") {
            next();
            if (to.query.type) {
                document.title = to.query.type === 'fwxy' ? '朗国物联网平台-用户协议' : '朗国物联网平台-隐私政策';
            }
            return;
        }
        if (to.path === "/help") {
            next();
            if (to.query.type) {
                document.title = to.query.type === 'jjfa' ? '解决方案' : '重置门锁';
            }
            return;
        }
        if (to.path === "/" || to.path === "/download") {
            next();
            return;
        }
        if (token.getToken().accessToken) {
            next()
        } else {
            next({path: '/login'})
            document.title = '朗国物联网平台-登录'
        }
    } else {
        next();
    }
});

/**
 * 重置路由
 */
export function resetRouter() {
    const newRouter = new Router(config);

    // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
    router.matcher = newRouter.matcher;

    //清空vuex中存储的菜单
    app.$store.commit('login/SET_MENU_DATA', {
        homeMenuList: [],
        settingMenuList: [],
        id: 0
    })
}

export default router;
