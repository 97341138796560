import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/dvsgw";
} else {
    url = "/lgiot/device/dvsgw";
}

/**
 * 分页查询指定产品的调试设备列表,IOT平台调用
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     *productCode 产品编号
 * }
 * @returns {*}
 */
const queryDebugDevicePager = data => {
    return service({
        url: url + '/device/queryDebugDevicePager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询指定产品的虚拟设备列表,IOT平台调用
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     *productCode 产品编号
 * }
 * @returns {*}
 */
const queryVirtualDevicePager = data => {
    return service({
        url: url + '/device/queryVirtualDevicePager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 生成虚拟设备绑定码,IOT平台端调用
 * @param {
 *     *pid 产品PID
 * }
 * @returns {*}
 */
const generateVirtualBindCode = data => {
    return service({
        url: url + '/device/generateVirtualBindCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询虚拟设备绑定码是否还存在
 * @param {
 *     *bindcode 绑定码
 * }
 * @returns {*}
 */
const isVirtualBindCodeAlive = data => {
    return service({
        url: url + '/device/getVirtualBindCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除设备,IOT平台端调用，暂时只能删除调试和虚拟设备
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const deleteDevice = data => {
    return service({
        url: url + '/device/deleteDevice',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询设备是否存在
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const checkDeviceExist = data => {
    return service({
        url: url + '/device/checkDeviceExist',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询设备,IOT平台调用
 * @param {
 *    *currPage 当前页码
 *    *pageSize 每页返回记录数
 *    deviceId 设备ID
 *    isOnline 设备状态 - Y:在线; N:离线
 *    name 设备名称
 *    nenodeCode 子空间code
 *    ownerId 用户ID
 *    pid 产品PID
 *    spaceCode 空间code
 *    status 状态 - 1:未激活; 2:已激活
 *    type 设备类型 1.正常 2.虚拟 3.预览
 *    virtualId 虚拟ID，与设备ID必须传1个
 * }
 * @returns {*}
 */
const queryDevice = data => {
    return service({
        url: url + '/device/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryDebugDevicePager,
    queryVirtualDevicePager,
    generateVirtualBindCode,
    isVirtualBindCodeAlive,
    deleteDevice,
    checkDeviceExist,
    queryDevice
}
