/**
 * 个人中心菜单
 */
const userMenu = [
    {
        "code": "1",
        "parentCode": "1",
        "name": "个人信息",
        "level": 1,
        "isLeaf": "N",
        "uri": "/setting/userInfo",
        "children": []
    },
    {
        "code": "2",
        "parentCode": "2",
        "name": "企业管理",
        "level": 2,
        "isLeaf": "Y",
        "uri": "/setting/enterpriseInfo",
        "children": [
            {
                "code": "3",
                "parentCode": "2",
                "name": "成员管理",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/setting/member",
                "children": []
            }
        ]
    }
];

/**
 * 首页菜单
 */
const homeMenu = [
    {
        "code": "1",
        "parentCode": "0",
        "name": "产品开发",
        "level": 1,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "10",
                "parentCode": "1",
                "name": "产品开发",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/index"
            },
            {
                "code": "11",
                "parentCode": "1",
                "name": "设备调试",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/debug"
            }
        ]
    },
    {
        "code": "2",
        "parentCode": "0",
        "name": "授权服务",
        "level": 1,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "20",
                "parentCode": "2",
                "name": "授权服务",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/empower"
            }
        ]
    },
    {
        "code": "3",
        "parentCode": "200",
        "name": "运维服务",
        "level": 2,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "30",
                "parentCode": "3",
                "name": "售后运维",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/afterSales",
                "pagePath": "@/views/maintain/afterSales"
            },
            {
                "code": "31",
                "parentCode": "3",
                "name": "设备日志",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/deviceLog",
                "pagePath": "@/views/maintain/deviceLog"
            },
            {
                "code": "32",
                "parentCode": "3",
                "name": "OTA升级",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/ota",
                "pagePath": "@/views/maintain/ota"
            }
        ]
    },
]

/**
 * 通信协议字典
 */
const protocolTypeList = [
    {id: 1, name: 'WiFi'},
    // {id: 2, name: 'WiFi-蓝牙'},
    // {id: 3, name: 'WiFi-NFC'},
    // {id: 4, name: '蓝牙'},
    // {id: 5, name: 'Zigbee'},
    // {id: 6, name: 'NB-IoT'},
    // { id: 7, name: 'LTE Cat.1' },
    // { id: 8, name: 'LTE Cat.1-蓝牙' },
    // { id: 9, name: 'LTE Cat.M' },
    // { id: 10, name: 'LTE Cat.M-蓝牙' },
    // {id: 11, name: 'GPRS'},
];


/**
 * 产品状态字典
 */
const productStatusList = [
    {id: 1, name: '开发中'},
    {id: 2, name: '申请发布'},
    {id: 3, name: '已发布'}
]

/**
 * 产品状态颜色
 */
const productStatusColorList = [
    {id: 1, name: '#D46B08'},
    {id: 2, name: '#D46B08'},
    {id: 3, name: '#389E0D'}
]

/**
 * 认证企业状态列表
 */
const certifiedEnterpriseStatusList = [
    {id: 0, name: "认证中"},
    {id: 1, name: "认证成功"},
    {id: 2, name: "认证失败"}
]

/**
 * 升级方式
 */
const upgradeTypeList = [
    {id: -1, name: "全部"},
    {id: 0, name: "APK"},
    {id: 1, name: "固件"},
    {id: 2, name: "SDK"}
];

/**
 * 升级状态
 */
const upgradeStateList = [
    {id: 1, name: "升级成功"},
    {id: 0, name: "升级失败"}
];

/**
 * 地区列表
 */
const regionList = [
    {id: -1, name: "全部"},
    {id: 0, name: "广东"},
    {id: 1, name: "长沙"},
    {id: 2, name: "南昌"}
]

/**
 * 比例列表
 */
const proportionList = [
    {id: 5, name: "5%"},
    {id: 10, name: "10%"},
    {id: 15, name: "15%"},
    {id: 20, name: "20%"},
    {id: 25, name: "25%"},
    {id: 30, name: "30%"}
]

/**
 * 发布方式
 */
const releaseModeList = [
    {id: -1, name: "全部"},
    {id: 0, name: "未发布"},
    {id: 1, name: "灰度发布"},
    {id: 2, name: "全量发布"},
    {id: 2, name: "指定发布"},
]



/**
 * 属性格式字典
 */
const formatList = [
    {id: 0, name: '数值型'},
    // {id: 1, name: '整数型'},
    // {id: 2, name: '小数型'},
    {id: 3, name: '枚举型'},
    {id: 4, name: '布尔型'},
    {id: 5, name: '字符型'},
    {id: 6, name: '透传型'},
    {id: 7, name: '故障型'},
    // {id: 8, name: '短整型'},
    // {id: 9, name: '长整型'}
]

/**
 * 倍数列表
 */
const rateList = [
    {id: 0, name: '10^0'},
    {id: 1, name: '10^1'},
    {id: 2, name: '10^2'},
    {id: 3, name: '10^3'},
    {id: 4, name: '10^4'},
]

/**
 * 类型长度列表
 */
const typeByteList = [
    {id: 1, name: '1byte'},
    {id: 2, name: '2byte'},
    {id: 4, name: '4byte'}
]

/**
 * 属性格式字典
 */
const formatIDList = [
    {id: 0, name: 'Number'},
    // {id: 1, name: 'Integer'},
    // {id: 2, name: 'Float'},
    {id: 3, name: 'Enum'},
    {id: 4, name: 'Boolean'},
    {id: 5, name: 'String'},
    {id: 6, name: 'Raw'},
    {id: 7, name: 'Fault'},
    // {id: 8, name: 'Short'},
    // {id: 9, name: 'Long'}
]

/**
 * 访问权限字典
 */
const authorityList = [
    {id: 1, name: "只下发(wo)"},
    {id: 2, name: "只上报(ro)"},
    {id: 3, name: "可下发可上报(rw)"}
]

/**
 * 功能类别
 */
const effectTypeList = [
    {id: 1, name: "标准功能"},
    {id: 2, name: "自定义功能"}
];

/**
 * 授权状态
 */
const empowerStatusList = [
    // {id: -2, name: "追加中"},
    // {id: -1, name: "导入中"},
    {id: 0, name: "待审核"},
    {id: 1, name: "申请成功"},
    {id: 2, name: "申请失败"}
];

/**
 * 设备激活状态
 */
const deviceStateList = [
    {id: 1, name: "待激活"},
    {id: 2, name: "已激活"}
];

/**
 * 设备类型
 */
const deviceTypeList = [
    {id: 1, name: "正常"},
    {id: 2, name: "虚拟"},
    {id: 3, name: "预览"}
];

/**
 * 设备网络状态
 */
const deviceNetworkStateList = [
    {id: 0, name: "全部"},
    {id: 1, name: "在线"},
    {id: 2, name: "离线"}
]

/**
 * 工单类型
 */
const workOrderTypeList = [
    {id: 0, name: "全部"},
    {id: 1, name: "维修工单"},
    {id: 2, name: "客服咨询"}
]

/**
 * 工单状态
 * @type {*[]}
 */
const workOrderStatesList = [
    {id: 0, name: "全部"},
    {id: 1, name: "待处理"},
    {id: 2, name: "处理中"},
    {id: 3, name: "已处理"}
]

/**
 * 日志类型
 */
const logTypeList = [
    {id: 0, name: "全部"},
    {id: 1, name: "上报"},
    {id: 2, name: "下发"}
]

/**
 * 授权对象
 */
const empowerObjectList = [
    {id: 1, name: "产品"},
    {id: 2, name: "服务"}
];

/**
 * 授权目的
 */
const empowerObjectiveList = [
    {id: 1, name: "调试"},
    {id: 2, name: "量产"},
    // { id: 3, name: "授权" }
];

/**
 * 自动授权
 */
const autoAuthList = [
    {id: 1, name: "是"},
    {id: 0, name: "否"},
];

/**
 * 天线类型
 */
const antennaTypeList = [
    { id:1, name:"外置天线" },
    { id:2, name:"板载天线" },
    { id:9, name:"未知" }
];

/**
 * 激活方式
 */
const activeTypeList = [
    { id:1, name:"唯一标识激活" },
    { id:2, name:"License激活" },
    { id:3, name:"自动激活" }
]

/**
 * 文件上传地址
 */
//线下
// const uploadImgUrl =  '/lgiot/upload/fisgw/file/web/upload';
//线上
const uploadImgUrl =  '/lgiot/fisgw/file/web/upload';

const language = navigator.language;

/**
 * 授权文件上传地址
 */
//线下
// const uploadEmpowerUrl =  '/lgiot/empower/azsgw/authorization/readdata';
//线上
const uploadEmpowerUrl =  '/lgiot/azsgw/authorization/readdata';

/**
 * 图片上传密钥
 */
const data = {
    appKey: '2264387516719142695',
    appSecret: 'bab32650-c2fd-4418-9dcc-5381ee30021b'
};

/**
 * 文档中心地址
 */
const docUrl = "http://192.168.1.116:3121";

export default {
    userMenu,
    homeMenu,
    empowerStatusList,
    empowerObjectList,
    empowerObjectiveList,
    certifiedEnterpriseStatusList,
    protocolTypeList,
    productStatusList,
    productStatusColorList,
    formatList,
    formatIDList,
    authorityList,
    effectTypeList,
    uploadImgUrl,
    data,
    docUrl,
    autoAuthList,
    deviceStateList,
    uploadEmpowerUrl,
    regionList,
    deviceNetworkStateList,
    workOrderTypeList,
    workOrderStatesList,
    logTypeList,
    upgradeTypeList,
    releaseModeList,
    proportionList,
    upgradeStateList,
    antennaTypeList,
    rateList,
    typeByteList,
    activeTypeList,
    deviceTypeList,
    language
}
