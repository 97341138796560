import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import login from '@/store/modules/login';
import main from '@/store/modules/main';
import setting from '@/store/modules/setting';
import member from '@/store/modules/member';
import product from '@/store/modules/product';
import effect from '@/store/modules/effect';
import release from '@/store/modules/release';
import enterprise from '@/store/modules/enterprise';
import category from '@/store/modules/category';
import menu from '@/store/modules/menu';
import roleManage from '@/store/modules/roleManage';
import firmware from '@/store/modules/firmware';
import panel from '@/store/modules/panel';
import empower from '@/store/modules/empower';
import guidance from '@/store/modules/guidance';
import maintain from "@/store/modules/maintain";
import device from "@/store/modules/device";

const store = new Vuex.Store({
    modules: {
        login,
        main,
        setting,
        member,
        product,
        effect,
        release,
        enterprise,
        category,
        menu,
        roleManage,
        firmware,
        panel,
        empower,
        guidance,
        maintain,
        device
    }
});

export default store;
