import axios from 'axios'
import app from "../main";

/****** 创建axios实例 ******/
const service = axios.create({
    baseURL: "",// api的base_url
    // timeout: 10000  // 请求超时时间
});


/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(config => {
    if (config.isToken) {
        if (app.$token.getToken().accessToken) {
            config.headers['accessToken'] = app.$token.getToken().accessToken //向请求头部插入token
            config.headers['refreshToken'] = app.$token.getToken().refreshToken
            console.log('url:', config.url);
        } else {
            app.$router.go('/login');
            app.$dialog.showMessage("登录失效", app.$config.TOAST_ERROR)
            window.location.reload();
        }
    }
    //解决get方式不让设置Content-Type问题
    if (config.method === 'get') {
        config.data = true
    }
    config.timeout = config.timeout ? config.timeout : 10000
    config.headers['Content-Type'] = config.contentType;
    //国际化
    config.headers['language'] = navigator.language
    console.log(config)
    return config;
}, error => {  //请求错误处理
    // if (loding) loding.close();
    console.log("error：接口请求异常！！", JSON.stringify(error));
    app.$dialog.showMessage("接口请求失败", app.$config.TOAST_ERROR)
    return Promise.reject(error);
})

/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
    response => {  //成功请求到数据
        let data = response.data;
        if (Object.prototype.toString.call(data).includes("Blob")) {
            // 如果是下载
            return response
        }
        console.log(data);
        if (data.success) {
            return Promise.resolve(data);
        } else {
            switch (data.code) {
                case '2000':
                    app.$dialog.showMessage(data.resultMsg, app.$config.TOAST_ERROR);
                    app.$token.removeToken();
                    app.$router.push('/login');
                    window.location.reload();
                    return Promise.reject(data);
                default:
                    return Promise.reject(data);
            }
        }
    },
    error => {
        console.log("error：接口响应异常！！", JSON.stringify(error));
        app.$dialog.showMessage('接口响应异常', app.$config.TOAST_ERROR);
        return Promise.reject(error)
    }
)

export default service;
