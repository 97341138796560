import app from "../../main";

const roleManage = {

    namespaced: true,

    state: {

        addRoleDialogVisible: false,

        addRoleType: 1,//(1，新增角色 2，新增下级角色 3，编辑角色 4，查看角色)

        empowerDrawerVisible: false,

        roleMsgItem: {},

        roleMsg: []

    },

    getters: {},

    mutations: {

        SET_ARR_ROLE_DIALOG_VISIBLE: (state, addRoleDialogVisible) => {
            state.addRoleDialogVisible = addRoleDialogVisible
        },

        SET_ADD_ROLE_TYPE: (state, addRoleType) => {
            state.addRoleType = addRoleType;
        },

        SET_EMPOWER_DRAWER_VISIBLE: (state, empowerDrawerVisible) => {
            state.empowerDrawerVisible = empowerDrawerVisible
        },

        SET_ROLE_MSG_ITEM: (state, roleMsgItem) => {
            state.roleMsgItem = app.$config.deepCopy(roleMsgItem);
        },

        SET_ROLE_MSG: (state, roleMsg) => {
            state.roleMsg = app.$config.deepCopy(roleMsg);
        }

    },

    actions: {

        /**
         * 关闭角色弹窗
         * @param {*} param0
         */
        closeRoleDialog({commit}) {
            commit("SET_ARR_ROLE_DIALOG_VISIBLE", false);
            commit("SET_ROLE_MSG_ITEM", {});
        },

        /**
         * 关闭授权弹窗
         * @param {*} param0
         */
        closeEmpowerDrawer({commit}) {
            commit("SET_EMPOWER_DRAWER_VISIBLE", false);
            commit("SET_ROLE_MSG_ITEM", {});
        },

        /**
         * 分页获取角色列表（非树形结构）
         * @param {*} param0
         * @param {请求参数} data
         */
        async queryRole({commit}, data){
            await app.$api.role.queryRole(data).then(res => {
                res.result.totalRecords = parseInt(res.result.totalRecords);
                commit("SET_ROLE_MSG", res.result);
            }, err => {
                console.log(err);
                app.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', app.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取角色树形结构
         * @param {*} param0
         */
        async roleTree({commit}) {
            await app.$api.role.roleTree().then(res => {
                commit("SET_ROLE_MSG", res.result ? res.result : []);
            }, err => {
                console.log(err);
                commit("SET_ROLE_MSG", []);
                app.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', app.$config.TOAST_ERROR);
            })
        },

        /**
         * 新增角色
         * @param {*} param0
         * @param {请求参数} data
         * @returns
         */
        async addRole({commit}, data) {
            return await app.$api.role.addRole(data);
        },

        /**
         * 修改角色
         * @param {*} param0
         * @param {请求参数} data
         * @returns
         */
        async modifyRole({commit}, data) {
            return await app.$api.role.modifyRole(data);
        },

        /**
         * 删除角色
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async deleteRole({commit}, data) {
            return await app.$api.role.deleteRole(data);
        },

        /**
         * 获取角色拥有的权限列表
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getRoleauthorityList({commit}, data){
            return await app.$api.role.getRoleauthorityList(data);
        },

        /**
         * 取消角色权限选中
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async pitchoffauthorityRoleauthority({commit}, data) {
            return await app.$api.role.pitchoffauthorityRoleauthority(data);
        },

        /**
         * 角色权限选中
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async pitchonauthorityRoleauthority({commit}, data){
            return await app.$api.role.pitchonauthorityRoleauthority(data);
        },

        /**
         * 获取角色拥有的权限树
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getAuthTreeByCode({commit}, data) {
            return await app.$api.role.getAuthTreeByCode(data);
        }

    }

}

export default roleManage;
