
const setting = {

    namespaced: true,

    state: {

        updateTelDialogVisible:false,

        updatePasswordDialogVisible:false,

        updateUserDialogVisible:false

    },

    getters: {},

    mutations: {

        SET_UPDATE_TEL_DIALOG_VISIBLE: (state, updateTelDialogVisible) => {
            state.updateTelDialogVisible = updateTelDialogVisible;
        },

        SET_UPDATE_PASSWORD_DIALOG_VISIBLE: (state, updatePasswordDialogVisible) => {
            state.updatePasswordDialogVisible = updatePasswordDialogVisible;
        },

        SET_UPDATE_USER_DIALOG_VISIBLE: (state, updateUserDialogVisible) => {
            state.updateUserDialogVisible = updateUserDialogVisible;
        }

    },

    actions:{

        closeUpdateTelDialog({commit}) {
            commit("SET_UPDATE_TEL_DIALOG_VISIBLE", false);
        },

        closeUpdatePasswordDialog({commit}) {
            commit("SET_UPDATE_PASSWORD_DIALOG_VISIBLE",false);
        },

        closeUpdateUserDialog({commit}) {
            commit("SET_UPDATE_USER_DIALOG_VISIBLE",false);
        }

    }

}

export default setting;
